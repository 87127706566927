// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-react-customizable-progressbar-generator-js": () => import("./../../../src/pages/react-customizable-progressbar/generator.js" /* webpackChunkName: "component---src-pages-react-customizable-progressbar-generator-js" */),
  "component---src-pages-react-customizable-progressbar-index-js": () => import("./../../../src/pages/react-customizable-progressbar/index.js" /* webpackChunkName: "component---src-pages-react-customizable-progressbar-index-js" */),
  "component---src-pages-react-surfer-slider-index-js": () => import("./../../../src/pages/react-surfer-slider/index.js" /* webpackChunkName: "component---src-pages-react-surfer-slider-index-js" */)
}

